import React, { useCallback, useEffect } from "react";
import { ApplicationContext } from "../../../infrastructure/contexts/contexts";
import { Icon, SideMenu, SideMenuItem } from "@skodaflow/web-library";
import { IconButton, Tooltip } from "@mui/material";
import { useSwitch } from "./hooks/useSwitch";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { first, size } from "lodash";
import { getIcon, useModuleOpen } from "../../Modules/Modules";
import tokens from "@skodaflow/web-tokens/src/tokens.json";
import { IModuleConfiguration } from "../../../@marvin3/types/src";

const StyledItem = styled(SideMenuItem)`
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
`;

const style = {
	display: "flex",
	alignItems: "center",
	gap: "1rem",
	color: tokens["flow-colors"]["text-primary"],
};

export function ModulesButton() {
	const intl = useIntl();
	const open = useModuleOpen();
	const {
		marvinConfiguration: { modules },
	} = React.useContext(ApplicationContext);

	const [opened, onOpen, onClose] = useSwitch();

	const click = useCallback(
		(module: IModuleConfiguration) => {
			open(module);
			onClose();
		},
		[open, onClose]
	);

	const text = intl.formatMessage({ id: "menu" });

	return (
		<>
			<Tooltip title={text}>
				<IconButton aria-label={text} onClick={onOpen}>
					<Icon name="menu" fontSize="small" />
				</IconButton>
			</Tooltip>
			<SideMenu
				open={opened}
				handleClose={onClose}
				items={
					<>
						{modules.map((module) => (
							<div style={style} key={module.code} onClick={() => click(module)}>
								<StyledItem label={module.name} />
								{getIcon(module.authModuleInfo)}
							</div>
						))}
					</>
				}
			/>
		</>
	);
}
